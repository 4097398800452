import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/sleepaway-camp-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1983 slasher movie, Sleepaway Camp"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 13</h1>
                    <h2>Sleepaway Camp</h2>
                    <h3>July 24, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Robert Hiltzik // <b>Starring:</b> Felissa Rose &amp; Karen Fields</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/13-Sleepaway-Camp-e273fet" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe horror podcast, episode 13: Sleepaway Camp"></iframe>
                        </div>
                        <p><Link to="/transcripts/sleepaway-camp">Read the episode transcript</Link></p>
                        <p>This week, Bring Me The Axe! heads to Camp Arawak to explore one of slasher history's most unhinged entries, Sleepaway Camp. What could have been forgotten by history as just another Friday the 13th ripoff is elevated to a truly special place by a parade of dubious directorial decisions, a handful of positively insane performances, and a real wild twist ending that we spoil right from the get-go.</p>
                        <p><OutboundLink href="https://www.youtube.com/watch?v=T9K2ARikYzE&t=1s" target="_blank" rel="noreferrer">Trailer</OutboundLink></p>
                        <p><OutboundLink href="https://sleepawaycampfilms.com/" target="_blank" rel="noreferrer">Sources: sleepawaycampfilms.com</OutboundLink></p>
                        <p><OutboundLink href="https://www.dreadcentral.com/editorials/295274/going-back-to-sleepaway-camp-revisiting-the-problematic-classic/" target="_blank" rel="noreferrer">⁠Going Back To Sleepaway Camp by BJ Colangelo</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/madman">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)